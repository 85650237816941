<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.city" title="区域" align="center"/>
			<vxe-table-column slot="table-item" field="cfg.isvip" title="用户类型" align="center">
				<template v-slot="{ row }">
					<span>{{$userType[row.cfg.isvip]||"全部"}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.precent" title="折扣" align="center">
				<template v-slot="{ row }">
					<span>{{row.cfg.precent}}%</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.day" title="持续时间" align="center" />
			<vxe-table-column slot="table-item" field="cfg.electric" title="周期度数" align="center" />
			<vxe-table-column slot="table-item" field="cfg.dayreset" title="重置周期" align="center" />
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑</el-button>
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="用户类型" prop="isvip">
					<el-radio-group v-model="editDialogFormData.cfg.isvip">
						<el-radio label="0">全部</el-radio>
						<el-radio v-for="(item,k) of $userType" :key="k" :label="k">{{item}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="区域" prop="city">
					<el-select v-model="editDialogFormData.cfg.city" placeholder="请选择" style="width:100%">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠模式" prop="cfg">
					<el-radio-group v-model="editDialogFormData.cfg.tp">
						<el-radio :label="1">电费</el-radio>
						<el-radio :label="2">服务费</el-radio>
						<el-radio :label="3">电费+服务费</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="折扣">
					<el-input-number type="number" :min="0" :max="100" :step="1" :precision="0" v-model="editDialogFormData.cfg.precent" style="width: 128px;" class="input" size="small" placeholder="百分比">
					</el-input-number>%
				</el-form-item>
				<el-form-item label="优惠券" prop="status">
					<el-switch v-model="editDialogFormData.cfg.usecoupon" :active-value="1" :inactive-value="0" active-text="可用" inactive-text="不可用" clearable />
				</el-form-item>
				<el-form-item label="持续时间">
					<el-input-number type="number" :min="0" :max="10000" :step="1" :precision="0" v-model="editDialogFormData.cfg.day" style="width: 128px;" class="input" size="small" placeholder="天数">
					</el-input-number> 天
				</el-form-item>
				<el-form-item label="周期度数">
					<el-input-number type="number" :min="0" :max="10000" :step="1" :precision="0" v-model="editDialogFormData.cfg.electric" style="width: 128px;" class="input" size="small" placeholder="度数">
					</el-input-number> 度
				</el-form-item>
				<el-form-item label="重置周期">
					<el-input-number type="number" :min="0" :max="10000" :step="1" :precision="0" v-model="editDialogFormData.cfg.dayreset" style="width: 128px;" class="input" size="small" placeholder="天数">
					</el-input-number> 天
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'market-activitycommon-newcar',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '新车活动',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				selecttime: {},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					isvip: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("isvip" in this.editDialogFormData.cfg)) return callback(new Error('请选择用户类型'));
							callback();
						}
					}],
					city: [{
						required: true,
						message: '请选择区域',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("city" in this.editDialogFormData.cfg)) return callback(new Error('请选择区域'));
							callback();
						}
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			this.getList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 5,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				for(let v of res.data){
					v.cfg = JSON.parse(v.cfg);
					v.btime = v.btime.substr(0,10);
					v.etime = v.etime.substr(0,10);
				} 
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {cfg:{tp:1,precent:90,usecoupon:0,day:30,electric:100,dayreset:30}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({"tp":5}, this.editDialogFormData)
						}
						params.form.cfg = JSON.stringify(params.form.cfg);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ActivityCommon.edit(params);
						}else{
							await this.$api.Market.ActivityCommon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 返券记录
			detail(row) {
				this.$router.push({
					name: "market-activitycommon-newcaruser",
					query: {id:row.id},
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
